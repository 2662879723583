import React, { useState, useRef } from "react";
import style from "../../../pages/region/region.module.css";
import {
  useAddDistrictMutation,
  useGetCountriesSelectedQuery,
  useEditDistrictMutation,
} from "../../../redux/api/regionApi";

const AddDistricts = ({ setAddDistrict, district, setDistrict }) => {
  const { data, error, isSuccess } = useGetCountriesSelectedQuery({});
  const [nameEn, setNameEn] = useState(district._id ? district.name_en : "");
  const [nameAr, setNameAr] = useState(district._id ? district.namer_ar : "");
  const [countryID, setCountryID] = useState(
    district._id ? district.country : "0"
  );
  const [loading, setLoading] = useState(false);
  const [addDistrict] = useAddDistrictMutation();
  const [editDistrict] = useEditDistrictMutation();

  if (error) {
    alert(error.data.error);
  }

  const onSaveHandler = async () => {
    if (!nameEn) {
      alert("distrct name EN is required");
      return;
    }
    if (!nameAr) {
      alert("district name AR is required");
      return;
    }

    if (countryID === "0") {
      alert("country is required");
      return;
    }

    setLoading(true);

    const res = await addDistrict({
      district_en: nameEn,
      district_ar: nameAr,
      country_id: countryID,
    });

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error);
      }
    }
    if (res.data) {
      setAddDistrict(false);
      setDistrict({ _id: "" });
      setCountryID("0");
    }
    setLoading(false);
  };

  const editHandler = async () => {
    if (!nameEn) {
      alert("distrct name EN is required");
      return;
    }
    if (!nameAr) {
      alert("district name AR is required");
      return;
    }

    if (countryID === "0") {
      alert("country is required");
      return;
    }
    setLoading(true);
    const res = await editDistrict({
      district_id: district._id,
      district_en: nameEn,
      district_ar: nameAr,
      country_id: countryID,
    });

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error);
      }
    }

    if (res.data) {
      setNameAr("");
      setNameEn("");
      setDistrict({ _id: "" });
      setAddDistrict(false);
    }
    setLoading(false);
  };
  return (
    <div>
      <div className={style.headerWrapper}>Add District</div>
      <div className={style.adWrapper}>
        {loading && (
          <div>
            <span className="loader-spiner"></span>
            <span className="text-primary">...adding post</span>
          </div>
        )}

        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Name(EN)</div>
          <div>
            <input
              value={nameEn}
              type="text"
              className={style.nameTexts}
              onChange={(e) => {
                setNameEn(e.target.value);
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Name(AR)</div>
          <div>
            <input
              type="text"
              value={nameAr}
              className={style.nameTexts}
              onChange={(e) => {
                setNameAr(e.target.value);
              }}
              disabled={loading ? true : false}
            />
          </div>

          <div>
            {isSuccess && data.length > 0 && (
              <select
                onChange={(e) => setCountryID(e.target.value)}
                className={`${style.selectTexts} mt-3 mb-3`}
                defaultValue={countryID}
              >
                <option value="0">Select Country</option>
                {data.map((list, i) => {
                  return (
                    <option value={list._id} key={list._id + i}>
                      {list.country_english}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        </div>

        <div className={style.buttonWrapper}>
          <button
            className="btn btn-success"
            onClick={() => {
              if (district._id) {
                editHandler();
              } else {
                onSaveHandler();
              }
            }}
          >
            Save
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setAddDistrict(false);
              setDistrict({ _id: "" });
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDistricts;
