import React, { useEffect, useState } from "react";
import { IoIosLogOut, IoMdMenu, IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import NavbarMenu from "../components/navbar/NavbarMenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getLocalStorage } from "../utils/helperFunctions";
import { addUser } from "../redux/slices/UserSlice";
import Users from "../components/userlist/Users";
import Classifieds from "../pages/classifieds/Classifieds";
import Countries from "../pages/region/Countries";
import Districts from "../pages/region/Districts";
import Area from "../pages/region/Area";
import Banner from "../pages/banner/Banner";
import Posts from "../pages/posts/Posts";
import Stories from "../pages/stories/Stories";
import ClassifiedPosts from "../pages/classifieds/ClassifiedPosts";
import Dashboard from "../pages/DashBoard/Dashboard";
import Settings from "../pages/settings/Settings";
import ChangePassword from "../pages/settings/ChangePassword";
import ReportedUsers from "../components/userlist/ReportedUsers";
import ContactFrom from "../pages/contactform/ContactFrom";
import Tickets from "../pages/tickets/Tickets";
import Notification from "../pages/notifications/Notification";
import Logout from "./Logout";
import AddClassified from "../components/classifieds/AddClassified";
import AddClassifiedPost from "../pages/addclassifiedspost/AddClassifiedPost";
import AddDropDownsClassified from "../pages/addDropDownsClassified/AddDropDownsClassified";

const Rootlayout = ({ component }) => {
  const dispatch = useDispatch({});

  const [classifiedPost, setClassifiedPost] = useState("");
  const [navOpen, setNavOpen] = useState(false);
  useEffect(() => {
    if (typeof Storage !== "undefined") {
      const user = getLocalStorage();
      if (user)
        if (user.token) {
          dispatch(addUser(user));
        }
    } else {
      alert("Update browser");
    }
  }, [dispatch]);

  const logOuthandler = () => {
    localStorage.removeItem("RaselListing");
    window.location.replace("/#/rm-admin");
  };
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-none">
          <NavbarMenu
            setClassifiedPost={setClassifiedPost}
            setNavOpen={setNavOpen}
            navOpen={navOpen}
          />
          <Logout setNavOpen={setNavOpen} navOpen={navOpen} />
        </Col>
        <Col>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="d-none d-md-flex"
          >
            <span onClick={logOuthandler} className="cursor-pointer">
              <IoIosLogOut size={20} />
              <span style={{ marginLeft: "2px" }}>Logout</span>
            </span>
          </div>
          {component === "dashbaord" && <Dashboard />}
          {component === "userslist" && <Users />}
          {component === "classifieds" && <Classifieds />}
          {component === "countries" && <Countries />}

          {component === "districts" && <Districts />}

          {component === "areas" && <Area />}

          {component === "banners" && <Banner />}

          {component === "posts" && <Posts />}

          {component === "stories" && <Stories />}

          {component === "settings" && <Settings />}

          {component === "changepassword" && <ChangePassword />}

          {component === "reportedusers" && <ReportedUsers />}

          {component === "classifiedspost" && (
            <ClassifiedPosts
              classifiedPost={classifiedPost}
              setClassifiedPost={setClassifiedPost}
            />
          )}

          {component === "addclassifiedspost" && <AddClassifiedPost />}
          {component === "adddropdownsclassifeid" && <AddDropDownsClassified />}

          {component === "contactform" && <ContactFrom />}

          {component === "tickets" && <Tickets />}

          {component === "notifications" && <Notification />}
        </Col>
      </Row>
    </Container>
  );
};

export default Rootlayout;
