import React, { useState } from "react";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";
import Error from "../Error";
import style from "./table.module.css";

const ListTables = ({
  tableTitle,
  search,
  tableHeders = [],
  dataRemap,
  page,
  maxCountInaPage,
  setPage,
  errordata,
  useGetListHook,
  actions,
  paramAdditional,
}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const parms = {
    page: page ? page : 1,
    maxCountInaPage: maxCountInaPage ? maxCountInaPage : 10,
    searchText: searchText ? searchText : "",
    ...paramAdditional,
  };

  const { data, error, isLoading, isFetching, isSuccess } =
    useGetListHook(parms);

  console.log(data);

  if (error && error.status === 401) {
    removeLocalStorageAndNavigate(navigate);
  }

  return (
    <div className="width-100-overflow">
      <div className={style.tableBoxWrapper}>{tableTitle}</div>
      {search && (
        <div className={style.searchBoxWrapper}>
          <label className="mr-1">Search:</label>
          <input
            type="text"
            className={style.searchBox}
            onKeyUp={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      )}
      {(isLoading || isFetching) && (
        <div className="flex-item-center">
          <span className="loader-spiner"></span>
        </div>
      )}
      {error && (
        <Error
          error={error.data.error ? error.data.error : "some thing went wrong"}
        />
      )}
      {errordata && <Error error={errordata} />}
      {!isFetching && !isLoading && isSuccess && (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                {tableHeders.map((headervalue, index) => {
                  return (
                    <th scope="col" key={index + headervalue}>
                      {headervalue}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={style.listtbody}>
              {dataRemap(data).map((list, index) => {
                const objValue = Object.values(list);
                return (
                  <tr key={list._id}>
                    <th scopre="row">{index + 1}</th>
                    {objValue.map((el, index) => {
                      if (index > 0) {
                        if (typeof el === "boolean") {
                          return (
                            <td key={el + index}>
                              {el ? "active" : "inactive"}
                            </td>
                          );
                        } else {
                          return <td key={el + index}>{el}</td>;
                        }
                      }
                    })}

                    {actions && actions.length > 0 && (
                      <td>
                        {actions.map((action, index) => {
                          return (
                            <span
                              key={index}
                              className="mr-2 cursor-pointer"
                              onClick={() => action.handler(list)}
                            >
                              {list.isSelected
                                ? action.selectedIcon
                                : action.icon}
                            </span>
                          );
                        })}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page && (
            <div className={`flex-item-center m-1 cursor-pointer `}>
              <div className={`${style.pageNextPerviousBtnWrapper}`}>
                <div className={style.pagePreviousBtn}>
                  {page === 1 && (
                    <BiFirstPage
                      size={30}
                      style={{ color: "#7d7c7a", textDecoration: "underline" }}
                    />
                  )}
                  {page > 1 && (
                    <span onClick={() => setPage(page - 1)}>
                      <BiFirstPage size={30} />
                    </span>
                  )}
                </div>
                <span className={style.pageNumber}>{page}</span>
                <div className={style.pageNextBtn}>
                  {data.isNext && (
                    <span onClick={() => setPage(page + 1)}>
                      <BiLastPage size={30} />
                    </span>
                  )}
                  {!data.isNext && (
                    <BiLastPage size={30} style={{ color: "#7d7c7a" }} />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ListTables;
