import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const classifiedApi = createApi({
  reducerPath: "classifiedApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== "undefined") {
        const user = JSON.parse(localStorage.getItem("RaselListing"));
        if (user?.token) headers.set("Authorization", `Bearer ${user?.token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Classifieds",
    "Classifiedspost",
    "Classifiedapost",
    "ClassifiedsDropDown",
  ],
  endpoints: (builder) => ({
    addClassifiedsCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/add-category`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Classifieds"],
    }),
    getClassifiedsCategory: builder.query({
      query: ({ searchText }) => ({
        url: `api/category/get-categories?${
          searchText ? `&textSearch=${searchText}` : ""
        }`,
      }),
      providesTags: ["Classifieds"],
    }),
    deleteClassifiedCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/delete-category`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Classifieds"],
    }),
    editClassifiedCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/edit-category`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Classifieds"],
    }),
    getClassifieds: builder.query({
      query: ({
        searchText,
        category_id,
        page,
        maxCountInaPage,
        country_id,
      }) => ({
        url: `api/classifieds/get-classifieds-by-admin?country_id=${country_id}&page=${page}&itemsPerPage=${maxCountInaPage}${
          searchText ? `&textSearch=${searchText}` : ""
        }${category_id ? `&category_id=${category_id}` : ""}`,
      }),
      providesTags: ["Classifiedspost"],
    }),
    getClassified: builder.query({
      query: ({ classified_id, country_id }) => ({
        url: `api/classifieds/get-aclassified?&country_id=${country_id}&classified_id=${classified_id}`,
      }),
      providesTags: ["Classifiedapost"],
    }),

    allowDisllowClassified: builder.mutation({
      query(data) {
        return {
          url: `api/classifieds/allow-ordisallow`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Classifiedapost"],
    }),
    getClassifiedsSubCategory: builder.query({
      query: ({ searchText }) => ({
        url: `api/category/get-sub-catgeory-by-admin?preference=1${
          searchText ? `&textSearch=${searchText}` : ""
        }`,
      }),
      providesTags: ["SubClassifiedsCat"],
    }),

    getcategoriesDropDown: builder.query({
      query: ({ idName, category_id }) => ({
        url: `api/category/get-value-of-sub-categories?identity_name=${idName}&category_id=${category_id}`,
      }),
      providesTags: ["ClassifiedsDropDown"],
    }),
    AddValuesToCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/add-values-for-sub-categories`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["ClassifiedsDropDown"],
    }),

    AddValuesBulkToCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/add-values-for-sub-categories-bulk`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["ClassifiedsDropDown"],
    }),
    getPcategoriesDropDown: builder.query({
      query: ({ category_id }) => ({
        url: `api/category/get-sub-catgeory?category_id=${category_id}&prefrence=1`,
      }),
    }),

    editSubClassifiedCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/edit-sub-category`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["SubClassifiedsCat"],
    }),
    deleteSubClassifiedCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/delete-sub-category-by-admin`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["SubClassifiedsCat"],
    }),
    addSubClassifiedsCategory: builder.mutation({
      query(data) {
        return {
          url: `api/category/add-sub-category`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["SubClassifiedsCat"],
    }),
    addClassifiedPost: builder.mutation({
      query(data) {
        return {
          url: `api/classifieds/${data.apiUrl}`,
          method: "POST",
          body: data.data,
        };
      },
      invalidatesTags: ["Classifiedspost"],
    }),
  }),
});

export const {
  useAddClassifiedsCategoryMutation,
  useGetClassifiedsCategoryQuery,
  useDeleteClassifiedCategoryMutation,
  useEditClassifiedCategoryMutation,
  useGetClassifiedsQuery,
  useGetClassifiedQuery,
  useGetClassifiedsSubCategoryQuery,
  useAllowDisllowClassifiedMutation,
  useEditSubClassifiedCategoryMutation,
  useAddSubClassifiedsCategoryMutation,
  useDeleteSubClassifiedCategoryMutation,
  useAddClassifiedPostMutation,
  useGetcategoriesDropDownQuery,
  useGetPcategoriesDropDownQuery,
  useAddValuesToCategoryMutation,
  useAddValuesBulkToCategoryMutation,
} = classifiedApi;
