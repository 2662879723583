import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BsEyeFill } from "react-icons/bs";
import style from "./classifieds.module.css";
import ListTables from "../../components/tables/ListTables";
import { useGetClassifiedsQuery } from "../../redux/api/classifiedApi";
import ViewClassifiedPost from "./ViewClassifiedPost";
import AddValuesToDropDowns from "../../components/classifieds/AddValuesToDropDowns";
import { useGetCountriesSelectedQuery } from "../../redux/api/regionApi";

const ClassifiedPosts = ({ classifiedPost, setClassifiedPost }) => {
  const Query = new URLSearchParams(useLocation().search);
  const [countryID, setCountryID] = useState("KW");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    if (!Query.get("classified")) {
      alert("classified param is missing in the url");
    }
    if (!Query.get("id")) {
      alert("classified Id is missing in the url");
    }
  }, [Query]);

  const { data, error, isSuccess } = useGetCountriesSelectedQuery({});

  if (error) {
    alert(error.data.error);
  }

  const dataRemap = (data) => {
    return data.classifieds.map(filter);
  };

  const filter = (obj) => {
    return {
      _id: obj._id,
      userPosted: obj.user_id ? obj.user_id.name : "dummy",
      id: obj._id,
      title: obj.title,
      description: obj.description,
    };
  };

  const viewHandler = async (list) => {
    setClassifiedPost(list);
  };

  const AddDropDOwn = () => {
    navigate(
      `/rm-admin/classifieds/add-dropdwons-classified?id=${Query.get(
        "id"
      )}&classified=${Query.get("classified")}`
    );
  };

  const AddClassified = () => {
    navigate(
      `/rm-admin/classifieds/add-classified-post?id=${Query.get(
        "id"
      )}&classified=${Query.get("classified")}`
    );
  };

  return (
    <div className="root-top-margin">
      <>
        {Query.get("classified") && !classifiedPost && (
          <div
            className={style.addIcon}
            style={{
              justifyContent: `${
                Query.get("classified") === "jobs" ||
                Query.get("classified") === "automotive"
                  ? "space-between"
                  : "end"
              }`,
            }}
          >
            {(Query.get("classified") === "jobs" ||
              Query.get("classified") === "automotive") && (
              <button className="btn btn-success" onClick={AddDropDOwn}>
                Add Values To DropDowns{" "}
                {Query.get("classified").replace("-", "&")}
              </button>
            )}

            <button className="btn btn-success" onClick={AddClassified}>
              Add {Query.get("classified").replace("-", "&")}
            </button>
          </div>
        )}
        {!classifiedPost && (
          <div style={{ marginLeft: "10px" }}>
            {isSuccess && data.length > 0 && (
              <select
                onChange={(e) => setCountryID(e.target.value)}
                className={`${style.selectTexts} mt-3 mb-3`}
                defaultValue={countryID}
              >
                {data.map((list, i) => {
                  return (
                    <option value={list._id} key={list._id + i}>
                      {list.country_english}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        )}
        {!classifiedPost && (
          <div>
            <ListTables
              search={true}
              tableTitle="Lists of classifieds"
              tableHeders={[
                "userPosted",
                "id",
                "title",
                "description",
                "Actions",
              ]}
              paramAdditional={{
                category_id: Query.get("id"),
                country_id: countryID,
              }}
              useGetListHook={useGetClassifiedsQuery}
              dataRemap={dataRemap}
              actions={[{ icon: <BsEyeFill />, handler: viewHandler }]}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
        {classifiedPost._id && (
          <ViewClassifiedPost
            setClassifiedPost={setClassifiedPost}
            classifiedPost={classifiedPost}
            country_id={countryID}
          />
        )}
      </>
    </div>
  );
};

export default ClassifiedPosts;
